import { Component, Fragment } from "react";
import Footer from "../component/layout/footer";
import Header from "../component/layout/header";
import About from "../component/section/about";
import About_2 from "../component/section/about-2";
import Banner from "../component/section/banner";
import Category from "../component/section/category";
import Instructor from "../component/section/instructor";


const Home = () => {
    return (
        <Fragment>
            <Header />
            <Banner />
            <Category />
            <About />
            <About_2 />
            <Instructor />
            <Footer />
        </Fragment>
    );
}
 
export default Home;