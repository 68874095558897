
import { Link } from "react-router-dom";

const subTitle = "Popular Category";
const title = "Popular Category For Learn";
const btnText = "Browse All Categories";


const categoryList = [
    {
        imgUrl: 'assets/images/category/icon/klinik.png',
        imgAlt: 'category klinik',
        title: 'KLINIK',
    },
    {
        imgUrl: 'assets/images/category/icon/repository.png',
        imgAlt: 'category repository',
        title: 'REPOSITORY',
    },
    {
        imgUrl: 'assets/images/category/icon/dokumen.webp',
        imgAlt: 'category dokumentasi',
        title: 'DOKUMENTASI 7 INFORMASI HUKUM',
    },
    {
        imgUrl: 'assets/images/category/icon/sehat.webp',
        imgAlt: 'category ayo sehat',
        title: 'AYO SEHAT',

    },
    {
        imgUrl: 'assets/images/category/icon/opac.png',
        imgAlt: 'category opac',
        title: 'OPAC',
    },
]


const Category = () => {
    return (
        <div className="category-section padding-tb">
            <div className="container">
                <div className="section-header text-center">
                    <span className="subtitle">{subTitle}</span>
                    <h2 className="title">{title}</h2>
                </div>
                <div className="section-wrapper">
                    <div className="row g-2 justify-content-center row-cols-xl-6 row-cols-md-3 row-cols-sm-2 row-cols-1">
                        {categoryList.map((val, i) => (
                            <div className="col" key={i}>
                                <div className="category-item text-center">
                                    <div className="category-inner">
                                        <div className="category-icon">
                                            <img src={`${val.imgUrl}`} alt={val.imgAlt} />
                                        </div>
                                        <div className="category-content">
                                            <Link to="/course"><h6>{val.title}</h6></Link>
                                        </div>
                                    </div>
                                </div>
                            </div>  
                        ))}
                    </div>
                    <div className="text-center mt-5">
                        <Link to="/course" className="lab-btn"><span>{btnText}</span></Link>
                    </div>
                </div>
            </div>
        </div>
    );
}
 
export default Category;