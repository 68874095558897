const title = "Budaya Sharing Knowladge";
const desc = "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Suspendisse ultrices gravida dictum fusce. ";


const AboutTwo = () => {
    return (
        <div className="about-section style-2 section-bg">
            <div className="container">
                <div className="row row-cols-xl-2 row-cols-1 flex-row-reverse">
                    <div className="col">
                        <div className="about-desc">
                            <div className="section-header">
                                <h2 className="title mb-4">{title}</h2>
                                <p>{desc}</p>
                                <div className="about-thumb mb-4">
                                    <img src="assets/images/about/width_1600.webp" alt="about" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="about-left">
                            <div className="about-thumb">
                                <img src="assets/images/about/width_1200.webp" alt="about" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
 
export default AboutTwo;