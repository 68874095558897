


const title = "Apa itu Lasmana ?";
const desc = "Layanan Sistem Manajemen Pengetahuan adalah layanan untuk berbagi pengetahuan bagi insan Poltekkes Kemenkes Yogyakarta untuk insan Kemenkes lainnya. Mendokumentasikan pengetahuan menjadi pembelajaran yang dapat memberi manfaat bagi orang lain.";





const About = () => {
    return (
        <div className="about-section">
            <div className="container">
                <div className="row row-cols-xl-2 row-cols-1 flex-row-reverse">
                    <div className="col">
                        <div className="about-desc">
                            <div className="section-header">
                                <h2 className="title">{title}</h2>
                                <p>{desc}</p>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="about-left">
                            <div className="about-thumb">
                                <img src="assets/images/about/s.webp" alt="about" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
 
export default About;